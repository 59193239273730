// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./DYzt1hGc5-0.js";

const enabledGestures = {lte3djogJ: {hover: true}};

const cycleOrder = ["lte3djogJ", "IsE6oFxxh", "NJTEevi4Z", "PjyD2l7ae"];

const serializationHash = "framer-ARI6U"

const variantClassNames = {IsE6oFxxh: "framer-v-1lvx4xa", lte3djogJ: "framer-v-1qz3bc3", NJTEevi4Z: "framer-v-10343ed", PjyD2l7ae: "framer-v-toxzti"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Mwo2KOPC6: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "lte3djogJ", JacobKnapp2: "PjyD2l7ae", Phone: "NJTEevi4Z", Tablet: "IsE6oFxxh"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lte3djogJ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lte3djogJ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1qz3bc3", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"lte3djogJ"} ref={ref ?? ref1} style={{backgroundColor: "rgb(153, 238, 255)", ...style}} variants={{"lte3djogJ-hover": {backgroundColor: "rgb(210, 243, 250)"}, IsE6oFxxh: {backgroundColor: "rgb(163, 69, 116)"}, PjyD2l7ae: {backgroundColor: "rgb(238, 245, 54)"}}} {...addPropertyOverrides({"lte3djogJ-hover": {"data-framer-name": undefined}, IsE6oFxxh: {"data-framer-name": "Tablet"}, NJTEevi4Z: {"data-framer-name": "Phone"}, PjyD2l7ae: {"data-framer-name": "JacobKnapp2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-alignment": "center"}}>Hei jacob</motion.p></React.Fragment>} className={"framer-bn1wg9"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"KDzFkt5X1"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({PjyD2l7ae: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7Q29taWMgTmV1ZS1yZWd1bGFy", "--framer-font-family": "\"Comic Neue\", sans-serif", "--framer-text-alignment": "center"}}>Hei jacob</motion.p></React.Fragment>, fonts: ["GF;Comic Neue-regular"]}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ARI6U.framer-3w8wt5, .framer-ARI6U .framer-3w8wt5 { display: block; }", ".framer-ARI6U.framer-1qz3bc3 { cursor: pointer; height: 51px; overflow: hidden; position: relative; width: 121px; }", ".framer-ARI6U .framer-bn1wg9 { flex: none; height: 20px; left: calc(49.586776859504155% - 80px / 2); position: absolute; top: calc(50.98039215686276% - 20px / 2); white-space: pre-wrap; width: 80px; word-break: break-word; word-wrap: break-word; }", ".framer-ARI6U.framer-v-1lvx4xa.framer-1qz3bc3, .framer-ARI6U.framer-v-10343ed.framer-1qz3bc3, .framer-ARI6U.framer-v-toxzti.framer-1qz3bc3 { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 121
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IsE6oFxxh":{"layout":["fixed","fixed"]},"NJTEevi4Z":{"layout":["fixed","fixed"]},"PjyD2l7ae":{"layout":["fixed","fixed"]},"Nd9M8Yp2z":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDYzt1hGc5: React.ComponentType<Props> = withCSS(Component, css, "framer-ARI6U") as typeof Component;
export default FramerDYzt1hGc5;

FramerDYzt1hGc5.displayName = "Jacobknappen";

FramerDYzt1hGc5.defaultProps = {height: 51, width: 121};

addPropertyControls(FramerDYzt1hGc5, {variant: {options: ["lte3djogJ", "IsE6oFxxh", "NJTEevi4Z", "PjyD2l7ae"], optionTitles: ["Desktop", "Tablet", "Phone", "JacobKnapp2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerDYzt1hGc5, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}, {family: "Comic Neue", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeDx6jj5pN1MwI.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})